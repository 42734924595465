.align-btn {
        padding: 0.5rem 1rem;
}

.align-btn.active {
    background: #777777;
}
.align-btn:hover {
    background: #77777780;
}
.align-btn.active:hover {
    background: #777777;
}