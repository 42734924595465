@tailwind base;
@tailwind components;
@tailwind utilities;



* {
    border-color: var(--fallback-bc,oklch(var(--bc)/0.2));
}

.Message .avatar {
    display: inline-block;
    vertical-align: middle;
    max-width: 48px;
    aspect-ratio: 1;
}

.Message .avatar > img {
    max-width: 100%;
}


.Toast {
    transition: 0.5s ease;
    position: relative;
    /* z-index: 100; */
    left: 0;
  }
  
  .Toast.close {
    left: 100px;
    opacity: 0;
  }
  
  .Toast .closeBtn {
    display: none;
  }
  
  .Toast:hover .closeBtn {
    display: block;
  }
  
  .slide-up {
    position: relative;
    animation: slideup 0.5s ease;
  }
  
  @keyframes slideup {
    0% {
      opacity: 0;
      top: 100px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }



.animate-grow {
    animation: growAnim 0.25s ease;
}

@keyframes growAnim {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fadeIn {
    animation: fadeIn 0.25s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}